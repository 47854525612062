<script setup lang="ts">
import moment from 'moment'
import RenameDialog from '@/views/admin/bots/RenameDialog.vue'
import UpdateDialog from '@/views/admin/bots/UpdateDialog.vue'
import { confirmedActionsCreator } from '@/utils/admin/actions'
import { paginationMeta } from '@api/utils/paginationMeta'
import { useCrud } from '@/pages/crud'
import type { User, UserProperties } from '@api/handlers/user/types'
import type { Bot, Exchange } from '@api/models/exchange'
import { BotStatus } from '@api/models/exchange'
import AmountUpdateDialog from '@/views/admin/bots/AmountUpdateDialog.vue'

definePage({
  meta: {
    auth: true,
    guard: 'admin',
    layout: 'admin',
  },
})

const sort = ref([{ key: 'created_at', order: 'desc' }])
const page = ref(1)
const sortBy = ref('')
const orderBy = ref('')
const searchQuery = ref('')
const itemsPerPage = ref(50)
const exchange = ref()
const user = ref()
const status = ref()
const rename = ref(false)
const update = ref(false)
const amountUpdate = ref(false)
const bot = ref<Bot | undefined>()

const selectedBots = reactive({
  selected: [],
  selectedStatus: computed<BotStatus[]>(() => Array.from(new Set(selectedBots.selected.map(bot => bot.status)))),
  hasEnabled: computed(() => {
    const statuses = selectedBots.selectedStatus
    if (statuses.length < 1)
      return false

    return statuses.includes(BotStatus.Enabled)
  }),
  hasDisabled: computed(() => {
    const statuses = selectedBots.selectedStatus
    if (statuses.length < 1)
      return false

    return statuses.includes(BotStatus.Disabled)
  }),
  isSold: computed(() => {
    return selectedBots.selectedStatus.includes(BotStatus.Sold)
  }),
  isEmpty: computed(() => {
    return selectedBots.selected.length < 1
  }),
})

const { data: responseData, execute: fetchBots, isFetching } = await useApi<PaginatedData<User>>(createUrl('/admin/bots', {
  query: {
    q: searchQuery,
    per_page: itemsPerPage,
    page,
    sortBy,
    orderBy,
    exchange,
    user,
    status,
  },
}))

interface BotStat {
  [key: string]: {
    balance: string
    total_profit: string
  }
}
const stats = ref<BotStat>([])

const pingBots = async () => {
  const ping = async () => {
    stats.value = await $api('/admin/bots/ping')
  }

  await ping()
  setInterval(async () => {
    await ping()
  }, 30000)
}

const createConfirmAction = confirmedActionsCreator(fetchBots)
const crud = useCrud<User, UserProperties>('/admin/bots', fetchBots)

const headers = [
  { title: 'Name', key: 'name' },
  { title: 'Bot ID', key: 'bot_id' },
  { title: 'Status', key: 'status' },
  { title: 'Amount', key: 'amount' },
  { title: 'Deals', key: 'total_deals', sortable: false },
  { title: 'Balance', key: 'balance', sortable: false },
  { title: 'Total Profit', key: 'total_profit', sortable: false },
  { title: 'Fee', key: 'fee', sortable: false },
  { title: 'Created', key: 'created_at' },
  { title: 'Expiry', key: 'expired_at' },
  { title: 'Actions', key: 'actions', sortable: false },
]

// Update data table options
const updateOptions = (options: any) => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

const botsData = computed(() => responseData.value?.data)
const total = computed(() => responseData.value?.total ?? 0)

const exchanges = ref<Exchange[]>([])
const exchangesLoading = ref(false)

const users = ref([])
const usersLoading = ref(false)

const getExchanges = async () => {
  exchangesLoading.value = true
  exchanges.value = await $api('/admin/exchanges?nopaginate')
  exchangesLoading.value = false
}

const getUsers = async () => {
  usersLoading.value = true
  users.value = await $api('/admin/users?nopaginate')
  usersLoading.value = false
}

const sendWarning = createConfirmAction('Are you sure you want to send warning to this bot user\'s?', (botId: number) => $api(`/admin/bots/${botId}/warning`, {
  method: 'POST',
}))

const syncBot = createConfirmAction('Are you sure you want to sync this bot?', (botId: number) => $api(`/admin/bots/${botId}/sync`, {
  method: 'POST',
}))

const panicSell = createConfirmAction((_bot: Bot) => `Are you sure you want to panic sell ${_bot.name} Bot`, (_bot: Bot) => $api(`/admin/bots/${_bot.id}/sell`, {
  method: 'POST',
}))

const deleteBot = createConfirmAction((_bot: Bot) => `Are you sure you want to Delete ${_bot.name} Bot`, (_bot: Bot) => $api(`/admin/bots/${_bot.id}`, {
  method: 'DELETE',
}))

const updateBotStatus = createConfirmAction((event: boolean) => `Are you sure you want to ${event ? 'Enable' : 'Disable'} this bot`, (event: boolean, _bot: Bot) => $api(`/admin/bots/${_bot.id}/${event ? 'enable' : 'disable'}`, {
  method: 'POST',
}))

const bulkSyncBot = createConfirmAction('Are you sure you want to sync selected bots?', () => $api('admin/bots/bulk/sync', {
  method: 'POST',
  body: {
    ids: selectedBots.selected.map((_bot: Bot) => _bot.id),
  },
}))

const bulkEnableBot = createConfirmAction('Are you sure you want to enable selected bots?', () => $api('admin/bots/bulk/enable', {
  method: 'POST',
  body: {
    ids: selectedBots.selected.map((_bot: Bot) => _bot.id),
  },
}))

const bulkDisableBot = createConfirmAction('Are you sure you want to disable selected bots?', () => $api('admin/bots/bulk/disable', {
  method: 'POST',
  body: {
    ids: selectedBots.selected.map((_bot: Bot) => _bot.id),
  },
}))

const bulkPanicSellBot = createConfirmAction('Are you sure you want to panic sell selected bots?', () => $api('admin/bots/bulk/sell', {
  method: 'POST',
  body: {
    ids: selectedBots.selected.map((_bot: Bot) => _bot.id),
  },
}))

const bulkDisconnectBot = createConfirmAction('Are you sure you want to disconnect selected bots?', () => $api('admin/bots/bulk', {
  method: 'DELETE',
  body: {
    ids: selectedBots.selected.map((_bot: Bot) => _bot.id),
  },
}))

const renameBot = (_bot: Bot) => {
  bot.value = _bot
  rename.value = true
}

const updateAmount = (_bot: Bot) => {
  bot.value = _bot
  amountUpdate.value = true
}

const updateBotSubscription = (_bot: Bot) => {
  bot.value = _bot
  update.value = true
}

onMounted(() => {
  getExchanges()
  getUsers()
  pingBots()
})

watch([exchange, status, user], () => {
  selectedBots.selected = []
})
</script>

<template>
  <section>
    <VCard
      title="Filters"
      class="mb-4"
    >
      <VCardText>
        <VRow>
          <VCol>
            <VSelect
              v-model="exchange"
              label="Select Exchange"
              :loading="exchangesLoading"
              :items="exchanges"
              item-title="name"
              item-value="id"
              clearable
            />
          </VCol>
          <VCol>
            <VSelect
              v-model="user"
              label="Select User"
              :loading="usersLoading"
              :items="users"
              item-title="name"
              item-value="id"
              clearable
            />
          </VCol>
          <VCol>
            <VSelect
              v-model="status"
              label="Select Status"
              :items="['Enabled', 'Disabled', 'Sold']"
              clearable
            />
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
    <VCard :loading="isFetching">
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <VBtn
            prepend-icon="tabler-refresh"
            :elevation="0"
            @click="fetchBots"
          >
            Refresh
          </VBtn>
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-bot-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <template v-if="!selectedBots.isEmpty">
            <VBtn
              v-if="selectedBots.hasDisabled"
              color="success"
              @click="bulkEnableBot"
            >
              Enable
            </VBtn>
            <VBtn
              v-if="selectedBots.hasEnabled"
              color="warning"
              @click="bulkDisableBot"
            >
              Disable
            </VBtn>
            <VBtn
              color="blue-darken-1"
              @click="bulkSyncBot"
            >
              Sync
            </VBtn>
            <VBtn
              v-if="!selectedBots.isSold"
              color="error"
              @click="bulkPanicSellBot"
            >
              Panic Sell
            </VBtn>
            <VBtn
              v-if="selectedBots.isSold"
              color="error"
              @click="bulkDisconnectBot"
            >
              Disconnect API
            </VBtn>
          </template>
          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model="selectedBots.selected"
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sort"
        :items="botsData"
        :headers="headers"
        :items-length="total"
        show-select
        return-object
        :loading="isFetching"
        :value-comparator="(bot1, bot2) => bot1.id === bot2.id"
        @update:options="updateOptions"
      >
        <template #item.amount="{ item }">
          ${{ formatNumber(item.amount) }}
        </template>

        <template #item.total_deals="{ item }">
          {{ item.active_deals }} / {{ item.max_active_deals }}
        </template>
        <template #item.expired_at="{ item }">
          {{ moment(item.expired_at).format('YYYY-MM-DD') }}
        </template>
        <template #item.balance="{ item }">
          {{ stats[item.bot_id].balance ?? 'Loading...' }}
        </template>
        <template #item.total_profit="{ item }">
          {{ stats[item.bot_id].total_profit ?? 'Loading...' }}
        </template>
        <template #item.created_at="{ item }">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </template>
        <template #item.fee="{ item }">
          %{{ item.fee }}
        </template>

        <!-- Actions -->
        <template #item.actions="{ item }">
          <div class="d-flex items-center">
            <VMenu>
              <template #activator="{ props }">
                <IconBtn v-bind="props">
                  <VIcon icon="tabler-dots-vertical" />
                </IconBtn>
              </template>

              <VList>
                <VListItem @click="renameBot(item)">
                  <template #prepend>
                    <VIcon icon="tabler-forms" />
                  </template>
                  Rename Bot
                </VListItem>
                <VListItem @click="updateAmount(item)">
                  <template #prepend>
                    <VIcon icon="tabler-currency-dollar" />
                  </template>
                  Update Trade Amount
                </VListItem>
                <VListItem @click="sendWarning(item.id)">
                  <template #prepend>
                    <VIcon icon="tabler-mail" />
                  </template>
                  Email to create new API
                </VListItem>
                <VListItem @click="syncBot(item.id)">
                  <template #prepend>
                    <VIcon icon="tabler-refresh" />
                  </template>
                  Sync Bot
                </VListItem>
                <VListItem :href="`https://app.3commas.io/bots/${item.bot_id}`">
                  <template #prepend>
                    <VIcon icon="tabler-link" />
                  </template>
                  Bot Link
                </VListItem>
                <VListItem @click="updateBotSubscription(item)">
                  <template #prepend>
                    <VIcon icon="tabler-edit" />
                  </template>
                  Update Bot Subscription
                </VListItem>
                <template v-if="item.status !== 'Sold'">
                  <VListItem @click="panicSell(item)">
                    <template #prepend>
                      <VIcon icon="tabler-alert-triangle" />
                    </template>
                    Panic Sell Bot
                  </VListItem>
                </template>
                <template v-else>
                  <VListItem @click="deleteBot(item)">
                    <template #prepend>
                      <VIcon icon="tabler-square-x" />
                    </template>
                    Delete Bot
                  </VListItem>
                </template>
              </VList>
            </VMenu>
            <VTooltip :text="`${item.status === 'Enabled' ? 'Disable' : 'Enable'} Bot`">
              <template #activator="{ props }">
                <VSwitch
                  v-bind="props"
                  inline
                  density="compact"
                  color="success"
                  base-color="error"
                  :model-value="item.status === 'Enabled'"
                  @update:model-value="($event) => updateBotStatus($event, item)"
                />
              </template>
            </VTooltip>
          </div>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, total) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(total / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(total / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>
  </section>
  <!-- Dialog -->
  <ConfirmDialog
    v-model="crud.deleteConfirmation.value"
    content="Are you sure you want to delete this bot?"
    @confirm="crud.destroy"
  />
  <RenameDialog
    v-model="rename"
    :bot="bot"
    @success="fetchBots"
  />

  <UpdateDialog
    v-model="update"
    :bot="bot"
    @success="fetchBots"
  />
  <AmountUpdateDialog
    v-model="amountUpdate"
    :bot="bot"
    @success="fetchBots"
  />
  <VDialog
    v-model="crud.loading.value"
    :scrim="false"
    persistent
    width="auto"
  >
    <VCard color="primary">
      <VCardText>
        Please stand by
        <VProgressLinear
          indeterminate
          color="white"
          class="mb-0"
        />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style>
.text-error .v-switch__thumb {
  background-color: #EA5455 !important;
}

.v-switch__track {
  background-color: white !important;
}
.v-switch {
  display: flex;
  justify-content: center;
}
</style>
